#input-message-default {
    width: 100%;

    .label-input-text {
        color: var(--gray-dark-super);
        font-family: "Roboto Light";
        font-weight: 500;
        font-size: 12px;
        line-height: 1.4;
        text-align: left;
    }

    textarea {
        margin-top: 4px;
        border: 1px solid var(--ash);
        border-radius: 24px;
        width: 100%;
        height: 120px;
        background-color: var(--white);
        padding: 14px 16px;
        color: var(--gray-dark);
        font-family: "Roboto Regular";
        font-size: 14px;
        resize: none;

        &::placeholder {
            color: var(--gray);
        }

        &.isWarning {
            color: var(--peach);
            outline: 1px solid var(--peach);
        }
    }

    @media only screen and (min-width: 30em) {
        }

        @media only screen and (width >= 80em) {
            }
        }
