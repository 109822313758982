#landing-page {
    .boat-info-wrapper {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 8px;
        gap: 40px;

        .title-paragraph-wrapper {
            margin-top: 80px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            text-align: center;
            max-width: 490px;

            img {
                width: 52px;
                height: 52px;
            }
        }

        .instant-book-button-container {
            width: 100%;
            max-width: 310px;
        }

        .yacht-cards-wrapper {
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;
            transition: all 0.3s ease-in-out;
        }

        .video-player {
            border: none;
            border-radius: 24px;
            width: 100%;
            height: auto;
            max-width: 1224px;
            aspect-ratio: 16/9;
        }

        .cards-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 24px;
        }

        #card-wrapper {
            max-width: 390px;
        }
    }

    @media only screen and (min-width: 53rem) {
        .boat-info-wrapper {
            padding: 0 24px;

            .yacht-cards-wrapper {
                gap: 24px;
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    @media only screen and (min-width: 80rem) {
        .boat-info-wrapper {
            .yacht-cards-wrapper {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
}

.landing-page {
    display: grid;

    .separator-padding {
        padding-left: 4%;
    }

    .form-reserve-carousel {
        position: relative;
        display: grid;

        #form-reserve-experience {
            order: 2;
        }

        #carousel-default {
            height: 420px;
            order: 1;
        }
    }

    @media only screen and (min-width: 48em) {
        .form-reserve-carousel {
            // grid-template-columns: auto auto;

            #form-reserve-experience {
                margin: 0 auto;
                //   padding: 40px 80px;
                //   display: flex;
                //   flex-direction: column;
                //   justify-content: center;
                //   align-items: center;
            }

            #carousel-default {
                height: 720px;
                //   order: 2;
            }
        }
    }

    @media only screen and (min-width: 80em) {
        .form-reserve-carousel {
            grid-template-columns: auto auto;

            #form-reserve-experience {
                padding: 80px 120px 0;
                order: 1;
            }

            #carousel-default {
                order: 2;
            }
        }
    }
}
