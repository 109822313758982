#yacht-card {
    border: 1px solid var(--ash);
    border-radius: 16px;
    display: grid;
    padding: 8px;
    gap: 8px;
    box-shadow: 0px 32px 32px -40px #00000040;
    max-width: 390px;

    &.border-highlighted {
        outline: 2px solid var(--royal);
    }

    .start-price {
        font-family: "Roboto Light";
        font-weight: 300;
        font-size: 12px;
        text-transform: uppercase;

        span {
            margin-left: 2px;
            color: var(--charcoal);
            font-weight: 600;
            font-size: 18px;
        }
    }

    hr {
        margin: 0;
        width: 100%;
        border-bottom: 1px solid var(--ash);
    }

    h4 {
        color: var(--charcoal);
        font-family: "Roboto Light";
        font-weight: 300;
        font-size: 24px;
    }

    .yacht-card__yacht-image-wrapper {
        cursor: pointer;

        .yacht-image-wrapper__content {
            border-radius: 16px;
            width: 100%;
            // width: 342px;
            max-height: 340px;
            aspect-ratio: 1/1;
            object-fit: cover;
        }

        .yacht-image-wrapper__start-price {
            display: grid;
            align-items: center;
            padding: 8px;
            justify-items: end;
            grid-template-columns: 1fr auto;

            .start-price__pre-text {
                margin-right: 4px;
                color: var(--gray-mid);
                font-family: "Roboto Light";
                font-weight: 300;
                font-size: 12px;
                text-transform: uppercase;
                grid-column: 1/3;
            }

            .start-price__price {
                color: var(--charcoal);
                font-weight: 600;
                font-size: 12px;
                grid-row: 2/3;
            }

            .start-price__pos-text {
                color: var(--gray);
                font-weight: 400;
                font-size: 12px;
                grid-row: 2/3;
            }
        }
    }

    .yacht-card__texts-wrapper {
        .texts-wrapper__text {
            color: var(--charcoal);
            font-family: "Roboto Light";
            font-weight: 300;
            font-size: 12px;
        }
    }

    .yacht-card__button-container {
        align-self: flex-end;
    }

    @media only screen and (min-width: 26.563rem) {
        .yacht-card__texts-wrapper {
            .texts-wrapper__text {
                font-size: 16px;
            }
        }
    }

    @media only screen and (min-width: 48em) {
        padding: 16px;
        gap: 16px;

        .yacht-card__yacht-image-wrapper {
            // padding-bottom: 20px;

            .yacht-image-wrapper__start-price {
                padding: 40px 8px 6px 8px;

                .start-price__pre-text {
                    padding-right: 8px;
                    grid-row: 1;
                }

                .start-price__price {
                    font-size: 24px;
                    grid-row: 1;
                }

                .start-price__pos-text {
                    font-size: 16px;
                    grid-row: 1;
                }
            }
        }

        .yacht-card__texts-wrapper {
            .texts-wrapper__text {
                font-size: 24px;
            }
        }
    }
}
