.slide-carousel-container {
  width: 100%;
  height: 160px;

  .swiper-slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    z-index: 1;
    top: 60%;
    background-color: var(--white);
    color: var(--black);
    font-size: 5px;
    transform: translateY(-50%);

    &:after {
      color: var(--black);
      font-size: 15px;
    }

    &.swiper-button-prev {
      left: 0;
      border-radius: 0 8px 8px 0;
    }

    &.swiper-button-next {
      right: 0;
      border-radius: 8px 0 0 8px;
    }
  }
}
