#footer-default {
  width: 100%;

  .copy-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 40px 0;
    color: var(--gray);
    font-family: "Roboto Regular";
    font-weight: 400;
    font-size: 18px;
    align-content: center;
    text-align: center;
    line-height: 1.4;
    max-width: 1224px;

    .footer-logo {
      margin-bottom: 24px;
      width: 64px;
      height: 64px;
    }

    .social-medias {
      margin-top: 24px;
      display: flex;
      gap: 24px;

      a {
        width: 24px;
        height: 24px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media only screen and (min-width: 30em) {
  }

  @media only screen and (width >= 80em) {
    .copy-right {
      margin: 0 auto;
      flex-direction: row;
      justify-content: flex-start;

      .footer-logo {
        margin-right: 24px;
        margin-bottom: 0;
      }

      .social-medias {
        margin-top: 0;
        margin-left: auto;
        justify-content: space-between;
      }
    }
  }
}
