#icon-and-subtitle {
    display: flex;
    align-items: flex-start;
    background-color: transparent;
    //margin: 16px 0;

    .subtitle-text {
        color: var(--gray);
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;

        &.futura {
            font-family: "Futura Book";
        }

        &.SF {
            font-family: "SF Pro Medium";
        }

        &.roboto {
            font-family: "Roboto Regular";
        }

        &.spectral {
            font-family: "Spectral Medium";
        }

        &.montserrat {
            font-family: "Montserrat Variable";
        }
    }

    .container {
        position: relative;
        margin-bottom: 12px;
        display: block;
        padding-left: 35px;
        font-size: 22px;
        cursor: pointer;
        user-select: none;

        input {
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
            cursor: pointer;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 6px;
            width: 24px;
            height: 24px;
            background-color: var(--charcoal);

            &:after {
                position: absolute;
                top: 4px;
                left: 9px;
                border: solid var(--charcoal);
                border-width: 0 3px 3px 0;
                width: 5px;
                height: 10px;
                display: none;
                content: "";
                transform: rotate(45deg);
            }
        }

        input:checked ~ .checkmark {
            border-radius: 6px;
            width: 24px;
            height: 24px;
            background-color: var(--pink-hot);

            &:after {
                display: block;
            }
        }
    }

    img {
        margin-top: 1%;
        margin-right: 8px;
        width: 1.125rem;
        height: 1.125rem;
        background-color: transparent;
    }
}
