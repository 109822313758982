.broker-yacht-list {
    margin-top: 80px;
    width: 100%;
    padding: 0 8px;

    .broker-yacht-list__broker-card {
        margin: 0 auto;
    }

    .broker-yacht-list__cards-wrapper {
        margin: 0 auto;
        display: grid;
        max-width: 1224px;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        transition: all 0.3s ease-in-out;
    }

    @media only screen and (min-width: 53rem) {
        .broker-yacht-list__cards-wrapper {
            gap: 24px;
            grid-template-columns: 1fr 1fr;
        }
    }

    @media only screen and (min-width: 80rem) {
        .broker-yacht-list__cards-wrapper {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
