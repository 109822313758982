#title-component {
    width: 100%;
    font-family: "Roboto Light";
    font-weight: 300;
    font-size: 32px;
    //line-height: 1.4;

    &.title-detail {
        color: var(--black);
        font-weight: 600;
        font-size: 24px;
        line-height: 1.4;
    }

    &.banner-title {
        font-size: 32px;
        //max-width: 400px;
    }

    &.title-color-black {
        color: var(--black);
    }

    &.title-color-white {
        color: var(--white);
    }

    @media only screen and (min-width: 48em) {
        &.banner-title {
            font-size: 40px;
        }
    }
}
