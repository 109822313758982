#smoky-container {
  position: relative;
  width: 100%;
  z-index: 2;
  height: 100%;

  img {
    width: 100%;
    height: 430px;
    object-fit: cover;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff00 99.45%);
    z-index: 1;
  }

  @media only screen and (min-width: 768px) {
    img {
    }
  }
}
