#price-card-wrapper {
    z-index: 1;
    border: 1px solid var(--ash);
    border-radius: 24px 24px 0 0 ;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    overflow-x: hidden;
}
