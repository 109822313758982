#listitem-wrapper {
  display: flex;
  align-items: flex-start;
  padding: 8px 0;

  .circle-icon {
    margin: 8px;
    margin-right: 24px;
    border: 2px solid var(--royal);
    border-radius: 4px;
    min-width: 8px;
    min-height: 8px;
    max-width: 8px;
    max-height: 8px;
  }

  .listitem-text-wrapper {
    // .title {
    //   color: var(--black);
    //   font-family: "Roboto Bold";
    //   font-size: 16px;
    //   line-height: 1.4;
    // }

    // .detail {
    //   color: var(--gray);
    //   font-family: "Roboto Regular";
    //   font-weight: 400;
    //   font-size: 16px;
    //   line-height: 1.4;
    // }
  }
}
