.toastContainer {
    position: fixed;
    z-index: 4;
    left: 50%;
    border-radius: 0 0 16px 16px;
    width: 100%;
    height: 94px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--royal);
    padding-right: 12px;
    transform: translateX(-50%);
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;

    &.show {
        top: 0px;
        opacity: 1;
    }

    &.hide {
        top: -100px;
        opacity: 0;
    }

    .iconContainer {
        position: absolute;
        top: 24px;
        left: 24px;
        margin-right: 16px;
        border: 1px solid rgba(222, 222, 222, 0.611);
        border-radius: 20px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .textContainer {
        margin-left: 80px;

        .textPrimary {
            margin-bottom: 4px;
            color: var(--white);
            font-family: "Roboto Regular";
            font-weight: 600;
            font-size: 16px;
            line-height: 1.4;
        }

        .textSecondary {
            color: var(--white);
            font-family: "Roboto Regular";
            font-weight: 600;
            font-size: 14px;
            line-height: 1.4;
        }
    }

    @media only screen and (min-width: 48em){
        border-radius: 16px 16px 16px 16px;
        width: 390px;

        &.show {
            top: 90px;
        }
    }
}
