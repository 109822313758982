#specs-pill-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;

  .highlight-item {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--ghost);
    padding: 8px;
    flex: 1;

    .highlight-text-top {
      color: var(--gray);
      font-size: 12px;
      line-height: 1.4;
      text-transform: uppercase;

      &.futura {
        font-family: "Futura Book";
      }

      &.SF {
        font-family: "SF Pro Medium";
      }

      &.roboto {
        font-family: "Roboto Light";
      }

      &.spectral {
        font-family: "Spectral Medium";
      }

      &.montserrat {
        font-family: "Montserrat Variable";
      }
    }

    .highlight-text-bottom {
      color: var(--black);
      font-family: "Roboto Light";
      font-size: 20px;
      line-height: 1.4;
      text-align: center;
    }
  }
}
