#detail-page {
  position: relative;
  overflow-x: hidden;

  .boat-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 24px 0 24px;

    [alt="Boat NX-horizon-40"] {
      width: 200px;
      height: 200px;
    }

    [alt="yacht charter logo"] {
      margin-bottom: 24px;
      width: auto;
      height: 48px;
    }

    .title-paragraph-wrapper {
      text-align: center;
      max-width: 640px;
    }
  }

  .detail-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .video-wrapper {
      width: 100%;
      padding: 0 24px 24px 24px;
      // .video-player {
      //     border-radius: 24px;
      //     width: 100%;
      // }

      .video-player {
        border: none;
        border-radius: 24px;
        width: 100%;
        height: auto;
        max-width: 1224px;
        aspect-ratio: 16/9;
      }
    }

    .detail-inner-wrapper {
      margin: 0 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // width: 100%;

      .detail-left {
        width: 100%;
        align-items: center;
        //padding: 0 24px;
        // display: flex;
        // flex-direction: column;
        // flex: 1;
        // order: 2;
      }

      .detail-right {
        width: 100%;
        // margin: 24px;
        // margin-top: 24px;
        // margin-bottom: 24px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // padding: 0 24px;
        // //max-width: 390px;
        // gap: 24px;
        // order: 1;
      }
    }
  }
  // .centered-content {
  //     margin: 0 auto;
  //     width: 100%;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     padding: 0 16px;
  //     max-width: 1224px;
  // }

  @media only screen and (min-width: 768px) {
    .boat-info-wrapper {
      padding: 40px 0;
    }

    .detail-wrapper {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 1224px;

      .video-wrapper {
        width: 100%;
        padding: 0 24px 0 0;
        padding-top: 40px;
        //margin-right: 24px;
        //margin: 0 auto;
        // .video-player {
        //     border-radius: 24px;
        //     width: 100%;
        //     height: auto;
        //     width: 100%;
        // }

        .video-player {
          border: none;
          border-radius: 24px;
          width: 100%;
          height: auto;
          max-width: 1224px;
          aspect-ratio: 16/9;
        }
      }

      .detail-inner-wrapper {
        width: 100%;
        flex-direction: row;
        align-items: flex-start;

        .detail-left {
          width: auto;
          align-items: flex-start;
          padding: 0;
        }

        .detail-right {
          margin-right: 24px;
          align-items: flex-start;
          padding-top: 40px;
          max-width: 390px;
        }
      }
    }
  }
  // @media only screen and (min-width: 80em) {
  //   .boat-info-wrapper {
  //     padding: 40px 0;
  //   }
  //   .detail-wrapper {
  //     display: flex;
  //     justify-content: center;
  //     padding: 0 24px;
  //     .detail-inner-wrapper {
  //       margin: 24px 0;
  //       display: flex;
  //       justify-content: space-between;
  //       align-items: flex-start;
  //       gap: 24px;
  //       max-width: 1224px;
  //       .detail-left {
  //         display: flex;
  //         flex-direction: column;
  //         padding: 0 24px;
  //         flex: 1;
  //       }
  //       .detail-right {
  //         width: 390px;
  //         display: flex;
  //         flex-direction: column;
  //         min-width: 390px;
  //         max-width: 390px;
  //         gap: 24px;
  //       }
  //     }
  //   }
  // }
  // @media only screen and (max-width: 438px) {
  //   .detail-wrapper {
  //     display: flex;
  //     justify-content: center;
  //     .detail-inner-wrapper {
  //       .detail-right {
  //         //max-width: calc(100% - 48px);
  //       }
  //       .detail-left {
  //         padding: 0 24px;
  //       }
  //     }
  //   }
  // }

  @media only screen and (min-width: 768px) {
    // overflow-x: hidden;
  }
}

#price-card-wrapper {
  position: sticky;
  z-index: 2;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  height: auto;
  background-color: var(--white);
  box-shadow: 0px -5px 20px 1px #0000002b;
}
