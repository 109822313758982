#nav-bar-landing-page {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px;
    background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
    );
    transition: background-color 0.3s ease;

    &.scrolled {
        background: var(--white);
        border-bottom: 1px solid var(--faded);
        box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.1);
    }

    .trigger {
        display: none;
    }

    .logo-container {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
            width: auto;
            height: 24px;
            //padding: 4px;
            cursor: pointer;
        }

        h1 {
            font-family: "Roboto Bold";
            font-size: 24px;

            sup {
                font-size: 12px;
            }
        }
    }

    .button-container-nav-bar {
        display: flex;
        align-items: center;
        gap: 24px;

        // #button-default {
            //     margin: auto;
            //     width: 150px;
            // }

            ul.nav {
                margin: 0;
                display: flex;
                padding: 0;
                list-style: none;
                //gap: 16px;
                transition: transform 0.3s ease-in-out;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    //width: 100px;
                    //margin: auto 0;
                    //height: 48px;
                }

                a {
                    width: 100%;
                    padding: 12px 24px;
                    color: var(--white);
                    font-family: "Montserrat Variable";
                    font-size: 12px;
                    line-height: 1.4;
                    text-decoration: none;
                    text-transform: uppercase;
                    text-align: center;
                    opacity: 85%;
                    cursor: pointer;

                    &:hover {
                        opacity: 100%;
                    }
                }

                p {
                    width: 100%;
                    padding: 12px 24px;
                    color: var(--white);
                    font-family: "Montserrat Variable";
                    font-size: 12px;
                    line-height: 1.4;
                    text-decoration: none;
                    text-transform: uppercase;
                    text-align: center;
                    opacity: 85%;
                    cursor: pointer;

                    &:hover {
                        opacity: 100%;
                    }
                }
            }

            &.open {
                ul.nav {
                    transform: translateX(0);
                }
            }

            &.scrolled {
                ul.nav {
                    li {
                        a {
                            color: var(--royal);

                            &:hover {
                                color: var(--black);
                            }
                        }

                        p {
                            color: var(--royal);

                            &:hover {
                                color: var(--black);
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 48em) {
            padding: 0 40px;

            .button-container-nav-bar {
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3411764706);

                ul.nav {
                    a,

                    p {
                        color: var(--royal);

                        &:hover {
                            color: var(--black);
                        }
                    }
                }
            }

            &.open {
                .trigger {
                    &:before,

                    &:after {
                        background: var(--royal);
                    }

                    &:before {
                        top: 50%;
                        left: 25%;
                        transform: rotate(45deg);
                    }

                    &:after {
                        top: 50%;
                        left: 25%;
                        transform: rotate(-45deg);
                    }

                    &.scrolled {
                        &:before {
                            top: 50%;
                            left: 25%;
                            transform: rotate(45deg);
                        }

                        &:after {
                            top: 50%;
                            left: 25%;
                            transform: rotate(-45deg);
                        }
                    }
                }

                .button-container-nav-bar {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 320px;
                    height: 100vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-top: 80px;
                    gap: 16px;
                    background: var(--white);
                    transform: translateX(0);
                    transition: transform 0.3s ease-in-out;

                    #button-default {
                        margin: 0 auto;
                    }
                }

                .button-container-nav-bar ul.nav {
                    margin-top: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 16px;
                    transition: transform 0.3s ease-in-out;
                    transform: translateX(0);
                }
            }

            .trigger {
                position: relative;
                z-index: 1;
                margin-top: 8px;
                width: 40px;
                height: 40px;
                display: inline-block;

                &.scrolled {
                    &:before,

                    &:after {
                        position: absolute;
                        width: 16px;
                        height: 2px;
                        content: "";
                        transform: translate(-50%, -50%);
                        background: var(--royal);
                        transition: ease 0.3s;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
                &:before,

                &:after {
                    position: absolute;
                    width: 16px;
                    height: 2px;
                    content: "";
                    transform: translate(-50%, -50%);
                    background: var(--white);
                    transition: ease 0.3s;
                }

                &:before {
                    top: 16px;
                    left: 50%;
                }

                &:after {
                    top: 24px;
                    left: 50%;
                }
            }

            .button-container-nav-bar {
                display: flex;
                align-items: center;
                gap: 24px;

                // #button-default {
                    //     margin: auto;
                    //     width: 150px;
                    // }

                    ul.nav {
                        margin: 0;
                        display: flex;
                        padding: 0;
                        list-style: none;
                        gap: 16px;
                        transition: transform 0.3s ease-in-out;

                        li {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            //width: 100px;
                            //margin: auto 0;
                            //height: 48px;
                        }
                        a,

                        p {
                            width: 100%;
                            padding: 12px 24px;
                            color: var(--white);
                            font-family: "Montserrat Variable";
                            font-size: 12px;
                            line-height: 1.4;
                            text-decoration: none;
                            text-transform: uppercase;
                            text-align: center;
                            cursor: pointer;

                            &:hover {
                                color: var(--royal);
                            }
                        }
                    }

                    &.open {
                        ul.nav {
                            transform: translateX(0);
                        }
                    }

                    &.scrolled {
                        ul.nav {
                            li {
                                a,

                                p {
                                    color: var(--royal);

                                    &:hover {
                                        color: var(--black);
                                    }
                                }
                            }
                        }
                    }
                }

                @media only screen and (max-width: 48em) {
                    z-index: 2;
                    padding: 0 40px;

                    .button-container-nav-bar {
                        ul.nav {
                            a,

                            p {
                                color: var(--royal);

                                &:hover {
                                    color: var(--black);
                                }
                            }
                        }
                    }

                    &.open {
                        .trigger {
                            &:before,

                            &:after {
                                background: var(--royal);
                            }

                            &:before {
                                top: 50%;
                                left: 25%;
                                transform: rotate(45deg);
                            }

                            &:after {
                                top: 50%;
                                left: 25%;
                                transform: rotate(-45deg);
                            }

                            &.scrolled {
                                &:before {
                                    top: 50%;
                                    left: 25%;
                                    transform: rotate(45deg);
                                }

                                &:after {
                                    top: 50%;
                                    left: 25%;
                                    transform: rotate(-45deg);
                                }
                            }
                        }

                        .button-container-nav-bar {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 320px;
                            height: 100vh;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top: 80px;
                            gap: 16px;
                            background: var(--white);
                            transform: translateX(0);
                            transition: transform 0.3s ease-in-out;

                            #button-default {
                                margin: 0 auto;
                            }
                        }

                        .button-container-nav-bar ul.nav {
                            margin-top: 0;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            gap: 16px;
                            transition: transform 0.3s ease-in-out;
                            transform: translateX(0);
                        }
                    }

                    .trigger {
                        position: relative;
                        z-index: 1;
                        margin-top: 8px;
                        width: 40px;
                        height: 40px;
                        display: inline-block;

                        &.scrolled {
                            &:before,

                            &:after {
                                position: absolute;
                                width: 16px;
                                height: 2px;
                                content: "";
                                transform: translate(-50%, -50%);
                                background: var(--royal);
                                transition: ease 0.3s;
                            }
                        }

                        &:hover {
                            cursor: pointer;
                        }
                        &:before,

                        &:after {
                            position: absolute;
                            width: 16px;
                            height: 2px;
                            content: "";
                            transform: translate(-50%, -50%);
                            background: var(--white);
                            transition: ease 0.3s;
                        }

                        &:before {
                            top: 16px;
                            left: 50%;
                        }

                        &:after {
                            top: 24px;
                            left: 50%;
                        }
                    }

                    .button-container-nav-bar {
                        display: none;
                    }
                }

                @media only screen and (min-width: 48em) {
                    z-index: 3;

                    .menu-item {
                        color: var(--royal);
                    }
                }
            }
        }
