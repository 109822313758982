#specifications-wrapper {
  position: relative;
  margin-top: 24px;
 border: 1px solid var(--ash);
  border-radius: 24px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  // width: 100%;

  .left {
    align-self: baseline;
  }

  .specs-highlight {
    margin: 12px 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .separator-list {
    width: 100%;
    height: 0;
    border-bottom: 1px solid var(--ash);
  }

  @media only screen and (min-width: 48em) {
    margin-right: 24px;
  }
}
