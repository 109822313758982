#select-dropdown {
  .select-label {
    color: var(--gray-dark-super);
    font-family: "Roboto Regular";

    font-weight: 500;
    font-size: 12px;

    &.disabled-label {
      color: var(--gray);
    }
  }

  .select-title {
    color: var(--gray-dark-super);
    font-family: "Roboto Regular";
    font-weight: 600;
    font-size: 16px;

    &.disabled-label {
      color: var(--gray);
    }
  }

  #select-default {
    margin-top: 4px;
    border: 1px solid var(--faded);
    border-radius: 24px;
    width: 100%;
    height: 48px;
    color: var(--gray-dark-super);
    font-family: "Roboto Regular";
    font-size: 14px;
    background: var(--chalk) url("../../../assets/icons/bx-chevron-down.svg")
      no-repeat calc(100% - 16px) 50%;
    cursor: pointer;

    &.disabled-select {
      //background-color: var(--dust);
      opacity: 40%;
      cursor: inherit;
    }

    &.isWarning {
      color: var(--peach);
      outline: 1px solid var(--peach);
    }

    .dropdown-header {
      margin: 0 16px;
      height: 100%;
      display: flex;
      align-items: center;

      p {
        color: var(--charcoal);
        font-size: 14px;
        align-self: center;

        &.placeholder-likes {
          color: var(--charcoal);
        }
      }

      [alt="icon select"] {
        z-index: 2;
        margin-right: 16px;
        width: 16px;
        height: 16px;
      }
    }

    .dropdown-wrapper {
      position: relative;

      .dropdown-list {
        position: absolute;
        z-index: 3;
        left: 0;
        border: 1px solid var(--ash);
        border-radius: 8px;
        width: 100%;
        background-color: var(--white);
        max-height: 300px;
        overflow-y: auto;
        box-shadow: 0px 8px 10px 0px #75757515;

        li {
          display: flex;
          align-items: center;
          background-color: var(--white);
          padding: 16px;
          font-family: "Roboto Regular";
          font-size: 14px;
          transition: background-color 0.3s ease;
          // border-radius: 16px;
          // margin: 16px;
          text-align: center;

          &:hover {
            background-color: var(--ghost);
          }

          p {
            color: var(--charcoal);
            font-size: 14px;
          }

          &:hover {
            cursor: pointer;
          }

          // &.li-disabled {
          //     // background-color: var(--ghost);
          //     // color: var(--gray);
          //     background: red;

          //     &:hover {
          //         background-color: rgba(204, 204, 204, 0.5);
          //         cursor: not-allowed;
          //     }
          // }

          .option-image {
            margin-right: 12px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 48em) {
    #select-default {
      .modal-overlay-select {
        display: none;
      }
    }
  }
}
