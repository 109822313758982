#contact-broker-modal {
  margin: 0px auto;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 40px;
  width: 100%;
  max-width: 480px;
  border-radius: 24px;
  box-shadow: 0px 32px 32px -40px #00000040;
  height: 100%;

  &.modal-message {
    height: min-content;
    padding: 40px 24px;
  }

  .modal-form-finish-message {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      margin-bottom: 24px;
      width: 40px;
      height: 40px;
      padding: 4px;
    }

    .message-title {
      margin-bottom: 8px;
      font-family: "Roboto Regular";
      font-weight: 400;
      font-size: 24px;
      line-height: 1.4;
    }

    .message-subtitle {
      color: var(--gray);
      font-family: "Roboto Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
    }
  }
  .modal-form-finish-message__buttons-container {
    display: grid;
    gap: 16px;
  }

  &::backdrop {
    background: rgba(0, 0, 0, 0.3);
  }

  .contact-broker-modal__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    [alt="close modal icon"] {
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;
    }

    .wrapper__header-texts {
      .header-texts__title {
        font-weight: 400;
        font-size: 24px;
        color: var(--black);
      }
    }

    .wrapper__form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .form__grid-template {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      }

      .grid-column-phone {
        display: grid;
        justify-content: space-between;
        grid-template-columns: 0.7fr 1fr;
        grid-template-rows: auto 1fr;
        // gap: 8px;

        .grid-column-phone__label {
          color: var(--gray-dark-super);
          font-family: "Roboto Regular";
          font-weight: 500;
          font-size: 12px;
        }

        .grid-column-phone__select {
          grid-row: 2/3;
        }

        .grid-column-phone__input {
          grid-row: 2/3;
          padding-left: 8px;
        }
      }
    }

    .wrapper__buttons-container {
    }

    .wrapper__footer {
      margin: 16px 0;
      display: grid;
      grid-template-columns: 24px 1fr;
      column-gap: 8px;

      strong {
        font-family: "Roboto Regular";
        font-weight: 600;
        font-size: 16px;
        line-height: 1.4;
      }

      p {
        color: var(--gray);
        grid-column: 2/3;
      }

      [alt="circle check"] {
        width: 24px;
        padding: 3px;
        grid-column: 1/2;
      }
    }
    .footer__last-text {
      #separator-default {
        margin: 12px;
      }
      .last-text__disclaimer {
        text-align: center;
      }
    }
  }

  @media only screen and (min-width: 48em) {
    padding: 24px;
    max-height: calc(100dvh - 48px);
  }
}
