:root {
  /* YACHT COLORS */
  --pink: #e7007c;
  --pink-hot: #ff007a;
  --pink-light: #ee4e87;
  --pink-alt: #ff4e69;
  --peach: #ff8383;
  --green: #a6cc50;
  --blue: #2081e2;
  --white: #ffffff;
  --ghost: #f9f9f9;
  --chalk: #f4f4f4;
  --dust: #eeeeee;
  --ash: #dddddd;
  --faded: #cccccc;
  --gray-light: #999999;
  --gray: #757575;
  --gray-mid: #555555;
  --gray-dark: #333333;
  --gray-dark-super: #363636;
  --charcoal: #232323;
  --midnight: #1a1a1a;
  --dawn: #111111;
  --black: #000000;
  --blue-light: #00a2b8;
  --blue-dark: #005d6a;
  --teal: #439ca4;
  --royal: #03425d;
  /* GRADIENT */
  --g-default: #e7007c;
  --g-inverse: #ff007a;
  /* DEFAULT PAGES */
  --default-color-background: var(--black);
  --default-color-header: var(--white);
  --default-color-text: var(--gray-light);
  --default-color-component: var(--midnight);
  --default-color-error: var(--peach);
  --default-color-success: var(--green);
  /* BREAKPOINTS SIZES */
  --bp-sm: 30em;
  --bp-md: 47em;
  --bp-lg: 75em;
  --bp-xlg: 100em;
}
/* FONTS */
/* YACHT FONT - MONTSERRAT */

@font-face {
  font-family: "Montserrat Variable";
  src: local("Montserrat"), local("Montserrat-Variable"),
    url("../fonts/Montserrat-Variable.ttf") format("truetype");
}
/* YACHT FONT - ROBOTO */

@font-face {
  font-family: "Roboto Regular";
  src: local("Roboto"), local("Roboto-Regular"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto"), local("Roboto-Bold"),
    url("../fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Italic";
  src: local("Roboto"), local("Roboto-Italic"),
    url("../fonts/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Light";
  src: local("Roboto"), local("Roboto-Light"),
    url("../fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Medium";
  src: local("Roboto"), local("Roboto-Medium"),
    url("../fonts/Roboto-Medium.ttf") format("truetype");
}
/* YACHT FONT - SPECTRAL */

@font-face {
  font-family: "Spectral Light";
  src: local("Spectral"), local("Spectral-Light"),
    url("../fonts/Spectral-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Spectral SemiBold";
  src: local("Spectral"), local("Spectral-SemiBold"),
    url("../fonts/Spectral-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Spectral Bold";
  src: local("Spectral"), local("Spectral-Bold"),
    url("../fonts/Spectral-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Spectral Medium";
  src: local("Spectral"), local("Spectral-Medium"),
    url("../fonts/Spectral-Medium.ttf") format("truetype");
}
/* YACHT FONT - SF PRO */

@font-face {
  font-family: "SF Pro Medium";
  src: local("SF Pro"), local("SFPRODISPLAYMEDIUM"),
    url("../fonts/SFPRODISPLAYMEDIUM.OTF") format("truetype");
}
/* YACHT FONT - FUTURA */

@font-face {
  font-family: "Futura Book";
  src: local("Futura"), local("FuturaCyrillicBook"),
    url("../fonts/FuturaCyrillicBook.ttf") format("truetype");
}
