#layout-default {
  width: 100%;
  display: grid;
  justify-items: center;
  position: relative;

  .main-container {
    width: 100%;
    display: grid;
    justify-content: center;
    position: relative;
  }

  @media only screen and (min-width: 30em) {
    .main-container {
      // padding: 0px 40px;
    }
  }

  @media only screen and (width >= 80em) {
    .main-container {
    }
  }
}
