#broker-card {
    width: 100%;
    padding: 24px;
    max-width: 493px;

    .broker-card__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .spacer {
            &.top {
                margin-bottom: 16px;
            }

            &.middle {
                margin: 16px 0;
            }
        }

        .wrapper__yacht-charter-logo-wrapper {
            [alt="yacht charter logo"] {
            }
        }

        .wrapper__broker-avatar-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            .broker-avatar-wrapper__avatar {
                margin: 0 auto;
                border-radius: 100%;
                width: 80px;
                height: 80px;
                object-fit: cover;
                outline: 1px solid var(--royal);
                cursor: pointer;
            }

            .broker-avatar-wrapper__name {
                padding-top: 4px;
                font-weight: 400;
                font-size: 18px;
            }

            .broker-avatar-wrapper__detail-text {
                color: var(--gray);
                font-weight: 400;
                font-size: 16px;
            }
        }

        .wrapper__button-wrapper {
            width: 100%;
            display: flex;
            padding: 16px 10% 40px 10%;
        }
    }
}
