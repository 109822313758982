#disclaimer-wrapper {
  width: 100%;

  .disclaimer-wrapper {
    margin: 0 auto;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--ghost);
    padding: 24px;
    // width: 100%;
    margin: 0 24px;

    .disclaimer-inner-wrapper {
      display: flex;
      gap: 16px;
      flex-direction: row;
      align-items: flex-start;
      padding: 0 24px;
      // max-width: 720px;

      [alt="circle icon"] {
        margin-right: 8px;
        width: 24px;
        height: 24px;
      }

      .caption-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      //   .caption-text-wrapper > *:first-child {
      //     margin-bottom: 16px;
      //   }
    }
  }

  @media only screen and (min-width: 48em) {
    .disclaimer-wrapper {
      margin: 0;
      margin-right: 24px;
    }
  }

  @media only screen and (min-width: 80em) {
    .disclaimer-wrapper {
      .disclaimer-inner-wrapper {
        padding: 0;
      }
    }
  }
}
