#yacht-booking-form {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  border: 1px solid var(--ash);
  width: 100%;
  height: 100vh;
  display: none;
  background-color: var(--white);
  padding: 24px;
  font-family: "Roboto Regular";
  overflow-y: auto;
  transition: all ease 0.3s;
}

#yacht-booking-form.show-form {
  margin-right: 24px;
  display: block;
  animation-name: openForm;
  animation-duration: 0.3s;
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  pointer-events: auto;

  @keyframes openForm {
    from {
      transform: translateY(100%);
      opacity: 0;
    }

    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  #separator-default {
    margin: 16px 0;
  }

  .header-form {
    margin-bottom: 20px;

    [alt="close icon"] {
      position: absolute;
      z-index: 2;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }

    .header-form-title {
      margin-bottom: 8px;
      font-family: "Roboto Regular";
      font-weight: 400;
      font-size: 24px;
      line-height: 1.4;
    }

    .header-form-subtitle {
      color: var(--gray);
      font-family: "Roboto Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
    }
  }

  .modal-form-finish-message {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      margin-bottom: 24px;
      width: 40px;
      height: 40px;
      padding: 4px;
    }

    .message-title {
      margin-bottom: 8px;
      font-family: "Roboto Regular";
      font-weight: 400;
      font-size: 24px;
      line-height: 1.4;
    }

    .message-subtitle {
      color: var(--gray);
      font-family: "Roboto Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
    }
  }

  .body-form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .body-form__broker-wrapper {
      display: flex;
      gap: 16px;
      align-items: center;
      border-bottom: 1px solid var(--ash);
      padding-bottom: 16px;
      margin-bottom: 16px;
      .broker-wrapper__avatar-wrapper {
        .avatar-wrapper__image {
          width: 64px;
          height: 64px;
          border-radius: 100%;
          object-fit: cover;
          cursor: pointer;
        }
      }

      .broker-wrapper__text-wrapper {
        .text-wrapper__broker-name {
          color: var(--black);
          font-size: 18px;
          font-weight: 400;
        }

        .text-wrapper__broker-detail {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .label-input-text {
      color: var(--gray-dark-super);
      font-family: "Roboto Regular";
      font-weight: 500;
      font-size: 12px;
      line-height: 1.4;
      text-align: left;
    }

    .grid-column {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
    }

    .widescreen-selects {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
    }

    .grid-column-phone {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 0.7fr 1fr;
      gap: 8px;
    }

    &.reservation-data-list {
      .separator {
        margin: 24px 0px;
        width: 100%;
        background-color: var(--ash);
      }

      .reservation-data__title {
        margin: 0;
        color: var(--black);
        font-family: "Roboto Regular";
        font-weight: 400;
        font-size: 24px;
      }

      .reservation-data__sub-title {
        color: var(--gray);
        font-family: "Roboto Regular";
        font-weight: 400;
        font-size: 16px;
      }

      .reservation-data {
        .reservation-data-sup,
        .reservation-data-sub {
          margin-bottom: 16px;
          display: grid;
          grid-template-columns: 1fr 1fr;

          .reservation-data__values {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .reservation-data__key-value {
              .data-key {
                color: var(--black);
                font-family: "Roboto Regular";
                font-weight: 500;
                font-size: 12px;
              }

              .data-value {
                color: var(--gray);
                font-family: "Roboto Regular";
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
        }

        .reservation-data__footer {
          .footer__total {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            font-family: "Roboto Regular";
            font-weight: 400;
            font-size: 14px;
            gap: 8px;
            text-transform: uppercase;

            span {
              color: var(--charcoal);
              font-weight: 600;
              font-size: 24px;
            }
          }

          #button.primary {
            margin: 16px 0 8px 0;
          }

          .text-explanatory {
            text-align: center;
          }
        }
      }
    }
  }

  .date-picker-container {
    width: 100%;
    display: grid;
    align-content: center;

    .input-date-picker {
      cursor: pointer;
      background: var(--white) url("../../../assets/icons/bx-chevron-down.svg")
        no-repeat calc(100% - 16px) 50%;
    }

    [alt="X icon"] {
      position: absolute;
      z-index: 4;
      top: 40px;
      right: 24px;
      cursor: pointer;
    }

    label {
      color: var(--gray-dark-super);
      font-family: "Roboto Regular";
      font-weight: 500;
      font-size: 12px;
      line-height: 1.4;
    }

    .date-picker-input {
      position: relative;
      margin: 1px 0;
      margin-top: 8px;
      border: 1px solid #c0c4d6;
      border-radius: 5px;
      width: 100%;
      height: 22px;
      padding: 2px 5px;
      padding-left: 16px;
      font-family: "Roboto Regular";
      font-size: 12px;
      height: 48px;
      border-radius: 24px;

      &.isWarning {
        outline: 1px solid var(--peach);
      }
    }

    .button-apply-date-picker {
      margin: 12px 0;
      border: none;
      border-radius: 24px;
      height: 48px;
      background-color: var(--royal);
      padding: 12px 24px;
      color: var(--white);
      font-family: "Montserrat Variable";
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: 2px;
      outline: none;
      cursor: pointer;
    }
  }

  .form-footer {
    margin-top: 8px;
    p,
    tr {
      color: var(--gray);
      font-family: "Roboto Light";
      font-weight: 400;
      font-size: 14px;
      line-height: 1.4;
    }

    .price-reserve {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-family: "Roboto Regular";
      gap: 4px;
      line-height: 1.4;

      strong {
        padding: 16px 0 8px 0;
        color: var(--charcoal);
        font-weight: 600;
        font-size: 24px;
        line-height: 1.4;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;
      }
    }

    .footer-form-container {
      display: grid;
      align-items: center;
      padding: 8px 0;

      h5 {
        padding: 16px 0;
      }

      table {
        width: 100%;

        tr {
          display: flex;
          justify-content: space-between;
          padding-bottom: 8px;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.4;

          th {
            color: var(--gray-dark-super);
            font-family: "Roboto Regular";
          }

          td {
            color: var(--black);
            font-family: "Roboto Regular";
            font-size: 14px;
          }

          &:last-child {
            margin-bottom: 8px;
            border-top: 1px solid var(--dust);

            td {
              font-weight: 500;
              font-size: 18px;
              line-height: 1.4;
            }
            th,
            td {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  .footer-cancellation {
    margin: 16px 0;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;

    strong {
      font-family: "Roboto Regular";
      font-weight: 600;
      font-size: 16px;
      line-height: 1.4;
    }

    p {
      color: var(--gray);
      grid-column: 2/3;
    }

    [alt="circle check"] {
      width: 24px;
      padding: 3px;
      grid-column: 1/2;
    }
  }

  .footer-last-text {
    color: var(--gray);
    text-align: center;
  }

  @media only screen and (min-width: 48em) {
    position: relative;
    margin-bottom: 24px;
    border: 1px solid var(--ash);
    border-radius: 24px;
    width: auto;
    height: inherit;
    display: block;
    animation-name: none;
    pointer-events: auto;
    overflow-x: inherit;
    overflow-y: inherit;
    max-height: 790px;
  }
}
