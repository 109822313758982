#paragraph-component {
    background-color: transparent;
    font-family: "Roboto Regular";
    font-size: 16px;
    line-height: 1.4;

    &.paragraph-color-black {
        color: var(--black);
    }

    &.paragraph-color-white {
        color: var(--white);
    }

    &.paragraph-color-gray-dark {
        color: var(--gray-dark);
    }

    &.paragraph-color-gray {
        color: var(--gray);
    }

    &.paragraph-color-dust {
        color: var(--dust);
    }

    @media only screen and (min-width: 48em) {
        font-size: 18px;
    }

    @media only screen and (min-width: 80em) {
        }
    }
