.react-datepicker {
    position: absolute !important;
    z-index: 3;
    top: 0;
    left: 0;
    margin: 0 !important;
    border: none !important;
    border-radius: 24px !important;
    padding: 0 !important;
    font-family: "Roboto Light" !important;
    background: var(--white) !important;
    box-shadow: 0px 0px 20px 0px #00000057 !important;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,

.react-datepicker__year-text--highlighted {
    background-color: rgb(67 156 164 / 10%) !important;
    color: black !important;
}

.react-datepicker__day {
    &:hover {
        border-radius: 50% !important;
    }

    &.react-datepicker__day--in-selecting-range:hover {
        border-radius: 50% !important;
    }

    &.react-datepicker__day--in-range:hover {
        border-radius: 0 !important;
    }

    &.react-datepicker__day--range-start:hover {
        border-radius: 50% 0 0 50% !important;
    }

    &.react-datepicker__day--range-end:hover {
        border-radius: 0 50% 50% 0 !important;
    }
}
.react-datepicker__day-name,
.react-datepicker__day,

.react-datepicker__time-name {
    margin: 1px 0 !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day-name,
.react-datepicker__day,

.react-datepicker__time-name {
    line-height: 2rem !important;
}

.react-datepicker__navigation--next {
    display: none !important;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,

.react-datepicker__year-text--keyboard-selected:hover {
    background-color: var(--royal) !important;
}
.react-datepicker__day--selected,

.react-datepicker__day--in-range {
    background-color: var(--royal) !important;
    color: white !important;
}

.react-datepicker__day--keyboard-selected {
    color: var(--white) !important;
}

.react-datepicker__header {
    border: none !important;
    background-color: var(--white) !important;
    padding: 24px 0 8px 0 !important;
    color: black !important;

    .react-datepicker__current-month {
        padding: 4px 0;
        color: black !important;
        font-weight: 400 !important;
        font-size: 14px !important;
    }
}
.react-datepicker__day--selected,

.react-datepicker__day--in-selecting-range {
    border-radius: 50% !important;
}

.react-datepicker__day--in-range {
    border-radius: 0 !important;
}

.react-datepicker__day--range-start {
    border-radius: 50% 0 0 50% !important;
}

.react-datepicker__day--range-end {
    border-radius: 0 50% 50% 0 !important;
}
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,

.react-datepicker__year-text--in-range {
    border-radius: 0 !important;
}

.react-datepicker__children-container {
    position: sticky;
    bottom: -24px !important;
    margin: 0 !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    padding: 0 24px 24px 24px !important;
}
.react-datepicker__day-name,
.react-datepicker__day,

.react-datepicker__time-name {
    width: 11% !important;
    font-family: "Roboto Regular";
    font-size: 14px;
}

.react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    border-color: var(--gray-light) !important;
    width: 7px !important;
    height: 7px !important;
}

.react-datepicker__navigation--previous {
    top: 16px;
    left: 2px;
}

.react-datepicker-popper {
    z-index: 3 !important;
    transform: inherit !important;
}

.react-datepicker-ignore-onclickoutside {
    border-radius: 24px !important;
    width: 100%;
    height: 48px;
}

.react-datepicker__input-container {
    input {
        border: 1px solid var(--gray);
        border-radius: 24px !important;
        width: 100%;
        height: 48px;
        padding-left: 16px;
        font-family: "Roboto Regular";
    }
}

.react-datepicker__month-container {
    width: 100vw !important;
    float: inherit !important;
}

.react-datepicker__month {
    margin: 0 0 24px 0 !important;
}

@media only screen and (min-width: 48em) {
    .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
        border-radius: 24px 24px 0 0 !important;
    }

    .react-datepicker__children-container {
        border-radius: 0 0 24px 24px;
        grid-column: 1/3;
    }

    .react-datepicker {
        top: 215px;
        left: -360px;
        border: 1px solid var(--ash) !important;
        display: grid !important;
        grid-template-columns: 1fr 1fr;
    }
    .react-datepicker__navigation--next,

    .react-datepicker__navigation--previous {
        top: 16px !important;
        display: flex !important;
        color: var(--white) !important;
    }

    .react-datepicker__month-container {
        max-width: 300px !important;
    }
    // .react-datepicker__day--in-selecting-range:not(
    // .react-datepicker__day--in-range,
    // .react-datepicker__month-text--in-range,
    // .react-datepicker__quarter-text--in-range,
    // .react-datepicker__year-text--in-range

    // ) {
        //     border-radius: 50% !important;
        // }
    }

    @media only screen and (min-width: 64em) {
        .react-datepicker {
            left: -360px;
        }

        // .react-datepicker__month-container {
            //     max-width: 440px !important;
            // }

            .react-datepicker__children-container {
                display: flex;
                align-items: center;

                #button-default.primary {
                    width: 60%;
                }
            }
        }

        @media only screen and (min-width: 66.563em) {
            .react-datepicker {
                left: -360px;
            }
        }

        @media only screen and (min-width: 69.438em) {
            .react-datepicker {
                top: 195px;
                left: -330px;
            }
        }

        @media only screen and (min-width: 75em) {
            .react-datepicker {
                left: -285px;
            }
        }

        @media only screen and (min-width: 76.5em) {
            .react-datepicker {
                left: -270px;
            }
        }
