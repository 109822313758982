#details-wrapper {
  box-sizing: border-box;

  .detail-list {
    margin-top: 40px;
  }

  .main-text-wrapper {
  }

  @media only screen and (min-width: 768px) {
    margin: 40px 0;

    .main-text-wrapper {
      margin-right: 24px;
    }

    .detail-list {
      margin-right: 24px;
    }
  }
}
