#spec-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  //height: 44px;
  //margin-top: 24px;

  .spec-list-item-left {
    margin-right: auto;
    color: var(--gray-mid);
    font-family: "Roboto Light";
    font-weight: 300;
    font-size: 12px;
    line-height: 1.4;
    text-transform: uppercase;
    text-align: left;
  }

  .spec-list-item-right {
    margin-left: 10px;
    display: flex;
    align-items: baseline;

    .spec-sub-item {
      margin-right: 8px;
      color: var(--gray-mid);
      font-family: "Roboto Light";
      font-size: 12px;
      line-height: 1.4;
    }

    .spec-main-item {
      color: var(--black);
      font-family: "Roboto Light";
      font-weight: 300;
      font-size: 18px;
      line-height: 1.4;
      text-align: right;
    }
  }
}
