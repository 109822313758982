.page-not-found {
  .page-not-found__wrapper {
    height: calc(100dvh - 186px);
    display: grid;

    .wrapper__card-not-found {
      align-self: center;
      max-width: 640px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      padding: 0 16px;

      .card-not-found__boat {
        width: 100%;
        max-width: 148px;
        height: 100%;
        max-height: 148px;
        object-fit: cover;
      }

      .card-not-found__404-text {
        color: var(--royal);
        font-weight: 300;
        font-size: 40px;
        font-family: "Roboto Light";

        b {
          font-family: "Roboto Regular";
          font-weight: 700;
        }
      }

      .card-not-found__buttons-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: 48em) {
    .page-not-found__wrapper {
      .wrapper__card-not-found {
        .card-not-found__buttons-container {
          flex-direction: row;
        }
      }
    }
  }
}
